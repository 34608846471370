$primary: #004795;
$secondary: #d40f0f;
$darker: #062f5a;
$secondary-darker: #062f5a;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "secondary-darker": $secondary-darker,
  "darker": $darker
);

$grid-breakpoints: (
        xs: 0,
        sm: 640px,
        md: 768px,
        lg: 1024px,
        xl: 1280px
);
$input-placeholder-color: #90a4ae;
$input-font-size: 13px;
$border-radius: .3rem;
$input-padding-y: .5rem;
$input-color: $secondary-darker;
$input-group-addon-bg: inherit;
$btn-font-weight: 500;
$btn-font-size: 13px;
$input-btn-padding-y: .5rem;

$nav-tabs-link-active-color: $primary;
$nav-tabs-border-color: transparent;
@import "../../src/assets/css/bootstrap.min.css";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/input-group";


.container {
  padding-left: 0;
  padding-right: 0;
}


$dropdown-link-active-color:        $dropdown-link-hover-color;
$dropdown-link-active-bg:           $dropdown-link-hover-bg;

.btn-link {
  &:focus {
    @apply shadow-none;
  }
}

.btn-primary {
  @apply text-white text-sm;
}

.btn-outline-brand-primary, .btn-outline-secondary {
  &:hover, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    @apply text-white;
  }
}

.btn-secondary {
  @apply text-white
}

.popper {
  &--lg {
    max-width: 440px;
  }
}

.text-inherit {
  text-align: inherit;
}


.table {
  th {
    color: $secondary-darker;
    text-align: left;
    border-top: none;
  }

  thead th {
    border-bottom: (2 * $table-border-width) solid $secondary-darker;
  }

  td {
    border-top: none;
    border-bottom: $table-border-width solid $table-border-color;
  }
}

.btn-link--inline {
  @apply leading-none p-0;
}

.nav-tabs {
  .nav-link {
    @apply font-bold text-gray-500 py-4 border-b-0;

    &:hover {
      @apply border-transparent;
    }

    &.active {
      @apply border-l-0 border-r-0 border-t-0;
      border-bottom: 4px solid $primary;
    }
  }
}


.nav-item {
  @apply inline-flex items-center border-b-2 border-transparent transition duration-150 ease-in-out;

  &__link {
    @apply text-sm font-medium leading-5 text-gray-500;

    &:hover {
      @apply text-gray-700 border-gray-300 cursor-pointer;
    }

    &--active {
      @apply border-indigo-500 text-gray-900;

      &:focus {
        @apply border-indigo-700;
      }
    }

    &:focus {
      @apply outline-none border-indigo-700 text-gray-700 border-gray-300;
    }
  }
}

.mobile-nav-item {
  @apply block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 transition duration-150 ease-in-out;

  &__link {
    @apply text-sm font-medium leading-5 text-gray-500;

    &:hover {
      @apply text-gray-700 border-gray-300 cursor-pointer;
    }

    &--active {
      @apply border-indigo-500 text-gray-900;

      &:focus {
        @apply border-indigo-700;
      }
    }

    &:focus {
      @apply outline-none border-indigo-700 text-gray-700 border-gray-300;
    }
  }
}


.modal-title {
  @apply text-darker font-bold text-xl;
}