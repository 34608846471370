@import "tailwind";
@import "bootstrap";

a {
  @apply text-blue-600;
}

th {
  @apply text-left;
}

hr {
  @apply my-4;
}


body, html {
  @apply bg-gray-50 text-gray-700;
}

.nav-tabs {
  border-bottom: 0px !important;

  .nav-link {
    @apply border-0 border-transparent text-sm font-normal text-gray-600;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    @apply text-secondary border-secondary;
  }
}

.topnav {
  @apply bg-white bg-no-repeat bg-right-bottom bg-auto;
  height: 80px;

  &-nav-item {
    @apply border-transparent border-b-4;
    @apply px-5 py-6;
    @apply flex items-center;
    @apply font-semibold text-darker tracking-wide;
    @apply bg-secondary;
    @apply transition ease-in-out duration-500;

    &:hover {
      @apply bg-white;
    }

    &--active {
      @apply border-darker bg-white;
    }
  }
}

.heading {
  &-a {
    @apply text-primary font-bold text-2xl tracking-wide mb-3;
  }

  &-b {
    @apply text-primary font-bold text-3xl tracking-wide;
  }

  &-c {
    @apply text-primary font-bold text-lg tracking-wide;
  }
}

.react-datepicker-wrapper {
  @apply block;
}

.form-control {
  &:focus {
    @apply border-secondary;
    box-shadow: 0 0 0 0.2rem #91bff0;
    outline: 0;
    
  }
}

.form-group {
  &__label {
    @apply block text-sm leading-5 text-gray-700 mb-1;
  }

  &.has-error {
    .form-control, .react-select__control {
      @apply border-red-500;
    }

    .form-group__label {
      @apply text-red-500;
    }
  }

  &--light {
    .form-group__label {
      @apply text-white;
    }
  }
  .input-group-text {
    &:focus {
      @apply border-2;
    }
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px theme('colors.secondary');
    border-color: theme('colors.secondary');
    }

  .react-select__option--is-focused, .react-select__option--is-selected
   {
    @apply bg-secondary text-white
  }

  .react-select__dropdown-indicator,
  .react-select__dropdown-indicator:hover {
    @apply text-darker;
  }
}
