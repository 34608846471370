:root{


  --primary-color:#53a239;

}

body {
    background-color: rgba(255, 255, 255, 255) !important;
    height: 100vh;
}




/* Additions For Template */




/* Slideshow  */
.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100vh;
    -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
  filter: blur(5px);
}

.each-slide-print-panel > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  /* padding: 10px */
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}



/* Login and Signup Form */
.login-container {
    height: 100vh;
    background-color: #efefef;

}

.login-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 5px 5px rgba(0,0,0,.2); /* Can always remove this if dropshadow is unwanted */
}



#allChildren{
    background-color: white;
}


/* Drag and drop interface */
.main-content-container{
    /* background-color: var(--primary-color); */
    /* height: calc(100vh - 72px); */
    /* width: 100%; */
    /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
    background-color: white;
}

.options-list{
    /* background-color: rgb(9, 145, 70); */
    /* height: calc(100%);
    width: 20%;
    max-width: 400px;*/
    padding: 10px;
    height: 100%;

    border-right: 1px solid rgba(0, 0, 0, 0.1);


    /* padding-right: 5px; */
}

.context-options-list{
    /* background-color: rgb(9, 145, 70); */
    /* height: calc(100%); */
    /* width: 20%; */
    /* max-width: 400px; */
    padding: 10px;
    height: 100%;

    border-left: 1px solid rgba(0, 0, 0, 0.1);


    /* padding-right: 5px; */
}


/* .options-list Input { */
    /* background-color: rgb(115, 228, 166); */
    /* margin-left: 5px;
    margin-right: 5px; */
/* } */

.drag-area{
    /* background-color: rebeccapurple; */
    height: calc(100%);
    /* width: 100%; */
    /* max-width: calc(100% - 250px); */
    /* width: 60%; */

}

.draggable-container{
    /* background-color: rgb(218, 98, 23); */
    height: 50px;
    width: 50px;
    position: absolute;
    object-fit: fill;
    top:0px;
    left:0px;

    /* max-width: 250px; */
}
.locked{
    /* background-color: rgb(218, 98, 23); */
    height: 50px;
    width: 50px;
    position: absolute;
    object-fit: fill;
    top:0px;
    left:0px;

    /* max-width: 250px; */
}
.draggable-container-circle{
    /* background-color: rgb(218, 98, 23); */
    height: 50px;
    width: 50px;
    position: absolute;
    object-fit: fill;
    top:0px;
    left:0px;

    /* max-width: 250px; */
}

/* .draggable-container-inside{
    position: absolute; */
    /* object-fit: fill; */
    /* max-width: 250px; */
/* } */

/* .draggable-container-outside{
    position: relative;
    height: 150px;
    width: 150px; */
    /* object-fit: fill; */
    /* max-width: 250px; */
/* } */


.draggable-container img {
    width: 100%;
   height: 100%;
   pointer-events: none;
}

.add-image-button{

    margin-bottom: 10px;

    width:100%;
    height:200px;
    background-size:cover;
}

.add-button-center-circle{
    /* width: 50px;
    height: 50px; */
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    /* top: calc(50%);
    left: calc(50%); */
    margin-top:10px;
    margin-left:10px;
    color: white;
    border: solid 1px black;
    opacity: 0.8;
    cursor: pointer;

}

.mini_circle{
    position: absolute;
    width: 10px;
    height: 10px;
    /* background-color: white; */
    left: calc(50% - 5px);
    top: calc(50% - 5px);
    border-radius: 100%;


}









.composite_area{
    /* background-color: rgb(218, 98, 23); */

    --height:300px;
    --width:500px;


    height: var(--height);
    width:  var(--width);
    /* margin-left: calc(50% - (var(--width)/2));
    margin-top: calc((50vh - 150px) -  (var(--height)/2)); */
    /* border: 2px dotted blue; */
}

.image-container{
    /* background-color: rgb(218, 98, 23); */
    width:100%;
    height:400px;
    overflow-y: auto;
    overflow-x: hidden;

}

.images-box {
    /* background-color: rgb(24, 24, 198); */
    width:100%;
    /* height: 100px; */

}



/* New Navigation Bar */
@media screen and (max-width: 770px) {
    .nav-sidebar{
        display: none !important;
    }
}

@media only screen and (min-width: 770px) {
    .navigation-item{
         border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    .nav-top-bar .sidebar-navigation-item{
        display: none !important;

    }
}

@media only screen and (min-width: 770px) and (min-width: 1000px) {
    .navigation-item, .navigation-item-options{
        font-size: small;
    }

}

/* End of new navigation bar */




.composites-select div { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
  }




  .graph-paper {
    height: 100%;
    background-color: rgb(107, 107, 107);
    background-size: 120px 120px;
    /* background-image: radial-gradient( #000000d0 5px, rgba(0, 0, 0, 0) 1px); */
    /* background-image:
    linear-gradient(to right, grey 5px, transparent 5px),
    linear-gradient(to bottom, grey 5px, transparent 5px); */
    background-image: radial-gradient(#bababa 10%, transparent 10%),
      radial-gradient(#fafafa 10%, transparent 10%);
    background-repeat: repeat;
}



.composite-editor-area{
 /* width: 2000px !important;
    height: 2000px !important; */
    width: 100000px;
    height: 100000px;
    position: absolute;
    left: calc(-50000px);
    top: calc(-50000px);
}



.wide-modal{
    min-width: 80%;
}

.md-modal{
    min-width: 60%;
}

.sm-modal{
    min-width: 40%;
}

.xs-modal{
    min-width: 20%;
}


.folder-tab-edit-button{
    border-color: rgb(226, 225, 225);
    padding:5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow:inset 0px 0px 0px 1px  rgb(226, 225, 225);
    -moz-box-shadow:inset 0px 0px 0px 1px  rgb(226, 225, 225);
    box-shadow:inset px 0px 0px 1px  rgb(226, 225, 225);
    font-size: x-large;
}